import React from 'react';
import { ImageDisplay } from "../Albums/Images/Image";
import { VideoPlayer } from "../Albums/VedioPlayer/VideoPlayer";
import './FancyGalleryLayout.css';

const FancyGalleryLayout = ({ media, carouselOpen, flag, setDeleteId, selectedMedia, handleMediaClick }) => {
    const createLayoutSections = () => {
        const sections = [];

        for (let i = 0; i < media.length; i += 6) {
            const sectionMedia = media.slice(i, i + 6);
            sections.push(
                <div key={`section-${i}`} className="gallery-section">
                    <div className="gallery-grid">
                        {/* Featured large image */}
                        {sectionMedia[0] && (
                            <div className="featured-image">
                                <div className={`media-wrapper featured ${selectedMedia.includes(sectionMedia[0].item.id) ? 'selected' : ''}`}>
                                    {sectionMedia[0].type === 'video' ? (
                                        <VideoPlayer
                                            id={sectionMedia[0].item.id}
                                            mediaId={i}
                                            carouselOpen={carouselOpen}
                                            src={sectionMedia[0].item.url}
                                            flag={flag}
                                            setDeleteId={setDeleteId}
                                            handleMediaClick={() => handleMediaClick(sectionMedia[0].item.id, i)}
                                        />
                                    ) : (
                                        <ImageDisplay
                                            mediaId={i}
                                            id={sectionMedia[0].item.id}
                                            src={sectionMedia[0].item.compressedUrl || sectionMedia[0].item.url}
                                            alt=""
                                            handleMediaClick={() => handleMediaClick(sectionMedia[0].item.id, i)}
                                            flag={flag}
                                            setDeleteId={setDeleteId}
                                        />
                                    )}
                                </div>
                            </div>
                        )}

                        {/* Right side smaller images */}
                        <div className="side-images">
                            {sectionMedia.slice(1, 3).map((item, index) => (
                                <div
                                    key={index}
                                    className={`media-wrapper small ${selectedMedia.includes(item.item.id) ? 'selected' : ''}`}
                                >
                                    {item.type === 'video' ? (
                                        <VideoPlayer
                                            id={item.item.id}
                                            mediaId={i + index + 1}
                                            carouselOpen={carouselOpen}
                                            src={item.item.url}
                                            flag={flag}
                                            setDeleteId={setDeleteId}
                                            handleMediaClick={() => handleMediaClick(item.item.id, i + index + 1)}
                                        />
                                    ) : (
                                        <ImageDisplay
                                            mediaId={i + index + 1}
                                            id={item.item.id}
                                            src={item.item.compressedUrl || item.item.url}
                                            alt=""
                                            handleMediaClick={() => handleMediaClick(item.item.id, i + index + 1)}
                                            flag={flag}
                                            setDeleteId={setDeleteId}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>

                        {/* Bottom row of smaller images */}
                        <div className="bottom-row">
                            {sectionMedia.slice(3, 6).map((item, index) => (
                                <div
                                    key={index}
                                    className={`media-wrapper small ${selectedMedia.includes(item.item.id) ? 'selected' : ''}`}
                                >
                                    {item.type === 'video' ? (
                                        <VideoPlayer
                                            id={item.item.id}
                                            mediaId={i + index + 3}
                                            carouselOpen={carouselOpen}
                                            src={item.item.url}
                                            flag={flag}
                                            setDeleteId={setDeleteId}
                                            handleMediaClick={() => handleMediaClick(item.item.id, i + index + 3)}
                                        />
                                    ) : (
                                        <ImageDisplay
                                            mediaId={i + index + 3}
                                            id={item.item.id}
                                            src={item.item.compressedUrl || item.item.url}
                                            alt=""
                                            handleMediaClick={() => handleMediaClick(item.item.id, i + index + 3)}
                                            flag={flag}
                                            setDeleteId={setDeleteId}
                                        />
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            );
        }
        return sections;
    };

    return (
        <div className="gallery-container">
            {createLayoutSections()}
        </div>
    );
};

export default FancyGalleryLayout;