import React, {useEffect, useRef, useState} from "react";
import { MediaRenderIcons } from "../Icons/Icons";
import './Image.css';

const Shimmer = () => (
    <div className="shimmer-placeholder">
        <div className="shimmer"></div>
    </div>
);

export const ImageDisplay = ({ src, mediaId, id, alt, className, handleMediaClick, flag, setDeleteId }) => {
    const userType = sessionStorage.getItem("userType");
    const [isVisible, setIsVisible] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const wrapperRef = useRef(null);

    useEffect(() => {
        if (!wrapperRef.current) return;

        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.disconnect();
                }
            },
            {
                root: null,
                threshold: 0.1,
            }
        );

        observer.observe(wrapperRef.current);
        return () => observer.disconnect();
    }, []);

    return (
        <div className="row-image-container">
            {userType === "host" && <MediaRenderIcons id={id} mediaId={mediaId} flag={flag} setDeleteId={setDeleteId} src={src}/>}
            <div ref={wrapperRef} className={`image-wrapper ${isLoaded ? "loaded" : ""}`}>
                {!isLoaded && <Shimmer />}
                {isVisible && (
                    <img src={src}
                        alt={alt}
                        className={`lazy-image ${isLoaded ? "visible" : ""}`}
                        onLoad={() => setIsLoaded(true)}
                        onClick={() => handleMediaClick(mediaId)}
                    />
                )}
            </div>
        </div>
    );
};

export const CarouselImageDisplay = ({ src }) => {
    return (
        <div className="carousel-image-container">
            <img src={src} alt="" className="carousel-image" />
        </div>
    );
}